import { useAuth0 } from '@auth0/auth0-react'
import { Accordion, Box, GridItem, useDisclosure } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import { Fragment, useRef, useState } from 'react'
import { Configure, SearchState, connectRefinementList } from 'react-instantsearch-core'
import { levelOfStudyBroadData } from 'taxonomy'
import { TEALIUM_SEARCH_FILTER_MAPS } from 'tracking'
import {
  FilterAccordionItem,
  FilterButton,
  FinderFacetMenuItem,
  FinderFilterPills,
  FinderResults,
  GridLayout,
  InstantSearchWrapper,
  PopularSearches,
  ResultFilters,
  ResultsFacetMenu,
  ResultsFilterModal,
  SearchAutocomplete,
  SortBy,
  StaticFacetRefinement,
  StudyOptionItemType,
  useHandleModalCloseResetData,
} from 'ui'
import { searchPlugins } from 'ui/src/components/Search/SearchAutocomplete'
import { useEnquiryModalOnSignIn, useSavedModalOnSignIn } from 'ui/src/forms/helpers/enquiryForm'
import { setNZLocation, sortAlphabetically } from 'utils'
import { createConfig, createURL } from 'utils/src/helpers/algoliaSearch'
import { createLevelOfStudyFacets } from 'utils/src/helpers/createLevelOfStudyFacets'
import useFacetValueFetch from 'utils/src/hooks/useFacetValueFetch'
import { EnquiryFormConnectProps } from '../../../connectors'
import { ALGOLIA_FILTER_MAPS, ALGOLIA_INDICES, ROUTES } from '../../../constants'
import { popularCourses } from '../../../constants/popularSearchItems'
import { getProfile, useUser } from '../../../context'
import useUpdateInventoryItem from '../../../hooks/useUpdateInventoryItem'
import SavedSuccessModal, { SavedModalStatusType } from '../../SavedSuccessModal'
import SignUpModal, { LoginConnectProps, SignUpType } from '../../SignUpModal'
import { CourseHits } from '../SearchHit'

const COURSE_PROVIDER_SELECT_LIMIT = 500
const DELIVERY_MODES_LIMIT = 10

const { indexName, searchClient } = createConfig({
  indexName: ALGOLIA_INDICES.COURSE_FINDER.MAIN,
})

const popularAreasOfStudyArray = popularCourses
  .map(({ label }) => {
    return {
      label,
      objectID: label,
    }
  })
  .sort(sortAlphabetically)

const facetQuery = searchPlugins.createFacetQuery({
  searchClient,
  indexName,
  resultsPagePath: ROUTES.COURSE.RESULTS,
  facet: 'areasOfStudy.name',
})

const searchQuery = searchPlugins.createSearchQuery({
  searchClient,
  indexName,
  resultsPagePath: ROUTES.COURSE.RESULTS,
})

const popularAreasOfStudy = searchPlugins.popularAreasOfStudyPlugin(popularAreasOfStudyArray)

const courseFinderVirtualList = [
  'levelOfStudy.name',
  'countries.name',
  'regions.name',
  'providerType.name',
  'providerSubtype.name',
  'areasOfStudy.name',
]

const VirtualRefinementList = connectRefinementList(() => null)
const VirtualRefinementLists = () => {
  return (
    <>
      {courseFinderVirtualList.map((attribute) => {
        return <VirtualRefinementList key={attribute} attribute={attribute} />
      })}
    </>
  )
}

CourseFinderResult.FilterInstitution = function CourseFinderFilterInstitution() {
  return (
    <Box>
      <ResultsFacetMenu
        heading="All providers"
        attribute="providerName"
        limit={COURSE_PROVIDER_SELECT_LIMIT}
      />
    </Box>
  )
}

CourseFinderResult.SortBy = function CourseFinderResultSortBy() {
  return (
    <Box>
      <SortBy
        defaultRefinement={ALGOLIA_INDICES.COURSE_FINDER.MAIN}
        items={[
          { value: ALGOLIA_INDICES.COURSE_FINDER.MAIN, label: 'Relevancy' },
          {
            value: ALGOLIA_INDICES.COURSE_FINDER.ASCENDING,
            label: 'A - Z',
          },
          {
            value: ALGOLIA_INDICES.COURSE_FINDER.DESCENDING,
            label: 'Z - A',
          },
        ]}
      />
    </Box>
  )
}

CourseFinderResult.Filters = function CourseFinderResultsFilters({
  levelOfStudyFacets,
}: {
  levelOfStudyFacets: FinderFacetMenuItem[] | []
}) {
  const deliveryModes = useFacetValueFetch({
    attribute: 'deliveryModes.name',
    indexName,
  })
  const areasOfStudy = useFacetValueFetch({
    attribute: 'areasOfStudy.name',
    indexName,
  })

  const providerSubtype = useFacetValueFetch({
    attribute: 'providerSubtype.name',
    indexName,
  })

  const regions = useFacetValueFetch({
    attribute: 'regions.name',
    indexName,
  })

  return (
    <Accordion defaultIndex={[0]} allowMultiple>
      <Configure />
      {/* Level Of Study */}
      <FilterAccordionItem attribute="levelOfStudy.name" title="Level Of Study">
        <StaticFacetRefinement staticItems={levelOfStudyFacets} attribute="levelOfStudy.name" />
      </FilterAccordionItem>
      {/* Areas Of Study */}
      <FilterAccordionItem attribute="areasOfStudy.name" title="Area Of Study">
        <StaticFacetRefinement staticItems={areasOfStudy} attribute="areasOfStudy.name" />
      </FilterAccordionItem>
      {/* Modes Of Study */}
      <FilterAccordionItem attribute="deliveryModes.name" title="Study Mode">
        <StaticFacetRefinement
          limit={DELIVERY_MODES_LIMIT}
          staticItems={deliveryModes}
          attribute="deliveryModes.name"
        />
      </FilterAccordionItem>
      {/* Regions */}
      <FilterAccordionItem attribute="regions.name" title="Regions Of Study">
        <StaticFacetRefinement staticItems={regions} attribute="regions.name" />
      </FilterAccordionItem>
      {/* Institution Type */}
      <FilterAccordionItem attribute="providerSubtype.name" title="Education Provider Type">
        <StaticFacetRefinement staticItems={providerSubtype} attribute="providerSubtype.name" />
      </FilterAccordionItem>
    </Accordion>
  )
}

CourseFinderResult.Results = function CourseFinderResultResults({
  nbHits,
  query,
  openEnquiryForm,
  saveInventoryItem,
  idsOfEnquiredInventoryItems,
}: {
  query: string
  nbHits: number
  openEnquiryForm: (props: EnquiryFormConnectProps) => void
  saveInventoryItem: (props: EnquiryFormConnectProps) => void
  idsOfEnquiredInventoryItems: string[]
}) {
  return (
    <FinderResults.Results>
      <FinderResults.ResultsBar>
        <CourseFinderResult.FilterInstitution />
        <CourseFinderResult.SortBy />
      </FinderResults.ResultsBar>
      {nbHits ? (
        <FinderResults.ResultsContainer sideBarHeading="Course">
          <CourseHits
            idsOfEnquiredInventoryItems={idsOfEnquiredInventoryItems}
            openEnquiryForm={openEnquiryForm}
            saveInventoryItem={saveInventoryItem}
          />
        </FinderResults.ResultsContainer>
      ) : (
        <FinderResults.NoResults query={query} />
      )}
      <FinderResults.Footer />
    </FinderResults.Results>
  )
}

const setNZSearchState = (searchState: SearchState) => {
  return setNZLocation(
    searchState,
    searchState?.refinementList?.['deliveryModes.name'] &&
      searchState.refinementList['deliveryModes.name'].includes('Study in NZ') &&
      !searchState?.refinementList?.['countries.name']?.includes('New Zealand')
  )
}

const FormModal = dynamic(() => import('ui/src/forms/FormModal/FormModal'))
const EnquiryFormConnect = dynamic(
  () => import('../../../connectors/EnquiryForm/EnquiryFormConnect')
)

function CourseFinderResult() {
  const idsOfEnquiredInventoryItems = useRef<string[]>([])

  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()

  const {
    isOpen: isSignUpOpen,
    onOpen: onSignUpModalOpen,
    onClose: onSignUpModalClose,
  } = useDisclosure()

  const {
    isOpen: isSaveModalOpen,
    onOpen: onSaveModalOpen,
    onClose: onSaveModalClose,
  } = useDisclosure()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const handleSaveItem = useUpdateInventoryItem(onSaveModalOpen)
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0()
  const { userDispatch } = useUser()

  const [currentLoginState, setCurrentLoginState] = useState<LoginConnectProps>({
    openModal: false,
  })

  const [savedStatus, setSavedStatus] = useState(SavedModalStatusType.Default)

  const [actionData, setActionData] = useState<EnquiryFormConnectProps | null>(null)

  const levelOfStudyFacets = createLevelOfStudyFacets(levelOfStudyBroadData)

  const handleModalCloseResetData = useHandleModalCloseResetData()

  const deliveryModes = useFacetValueFetch({
    attribute: 'deliveryModes.name',
    indexName,
  })

  useEnquiryModalOnSignIn(onModalOpen, setActionData)
  useSavedModalOnSignIn(onSignUpModalOpen, setSavedStatus)

  const handleEnquiryClick = async (props: EnquiryFormConnectProps) => {
    if (!isLoading) {
      setActionData(props)
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently()
        await getProfile(userDispatch, user, accessToken)
        onModalOpen()
      } else {
        onSignUpModalOpen()
        setCurrentLoginState({
          loginType: SignUpType.Enquiry,
          openModal: true,
          inventory: props,
        })
      }
    }
  }

  const handleSaveClick = async (props: EnquiryFormConnectProps) => {
    if (!isLoading) {
      setActionData(props)
      if (isAuthenticated) {
        handleSaveItem(props.formId, StudyOptionItemType.Course)
          .then(() => {
            setSavedStatus(SavedModalStatusType.Success)
          })
          .catch((err) => {
            setSavedStatus(SavedModalStatusType.Error)
          })
      } else {
        onSignUpModalOpen()
        setCurrentLoginState({
          loginType: SignUpType.Save,
          openModal: true,
          inventory: props,
        })
      }
    }
  }

  return (
    <Fragment>
      <InstantSearchWrapper
        indexName={indexName}
        searchClient={searchClient}
        searchState={{}}
        createURL={createURL}
        urlFilterMap={ALGOLIA_FILTER_MAPS.COURSE_FINDER_RESULTS}
        mapTealiumTrackingFields={TEALIUM_SEARCH_FILTER_MAPS.COURSE_FINDER_RESULTS}
      >
        <FinderResults>
          <FinderResults.Header title="Find a Course">
            <Configure />
            <VirtualRefinementLists />
            <GridLayout>
              <GridItem colSpan={{ base: 12, lg: 7 }}>
                <SearchAutocomplete
                  title="Search for a course"
                  placeholder="Search by name or keyword"
                  emptyQueryDropdownTitle="Popular areas of study"
                  resultsPath={ROUTES.COURSE.RESULTS}
                  facet="areasOfStudy.name"
                  plugins={[popularAreasOfStudy, facetQuery, searchQuery]}
                  showLabel={true}
                  size="lg"
                  shouldRedirectOnSubmit={false}
                  searchProviderCtaProps={{
                    href: ROUTES.PROVIDER.RESULTS,
                    topic: 'education providers',
                  }}
                />
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 5 }}>
                <PopularSearches
                  items={popularCourses}
                  popularSearchTitle="Popular areas of study"
                />
              </GridItem>
            </GridLayout>
            <ResultFilters
              searchClient={searchClient}
              indexName={indexName}
              isOpen={isOpen}
              onClose={onClose}
              editStateOnChange={setNZSearchState}
            >
              <CourseFinderResult.Filters levelOfStudyFacets={levelOfStudyFacets} />
            </ResultFilters>
            <FinderResults.FilterContainer>
              {/** @ts-ignore: This needs to be remedied, difficulty in typing a "HOC'd" HOC. See connectCurrentRefinment function. **/}
              <FilterButton onOpen={onOpen} ignoreRefinements={['providerName']} />
              {/** @ts-ignore: This needs to be remedied, difficulty in typing a "HOC'd" HOC. See connectCurrentRefinment function. **/}
              <FinderFilterPills ignoreRefinements={['providerName']} />
            </FinderResults.FilterContainer>
          </FinderResults.Header>
          <FinderResults.Body>
            {({ searchResults }) => {
              const { nbHits, query } = searchResults || {}
              return (
                <>
                  <CourseFinderResult.Results
                    nbHits={nbHits}
                    query={query}
                    openEnquiryForm={handleEnquiryClick}
                    saveInventoryItem={handleSaveClick}
                    idsOfEnquiredInventoryItems={idsOfEnquiredInventoryItems.current}
                  />
                  <ResultsFilterModal
                    staticItems={deliveryModes}
                    totalHits={nbHits}
                    attribute="deliveryModes.name"
                  />
                </>
              )
            }}
          </FinderResults.Body>
        </FinderResults>
      </InstantSearchWrapper>
      {isModalOpen && actionData && (
        <FormModal isOpen={isModalOpen} onClose={() => handleModalCloseResetData(onModalClose)}>
          <EnquiryFormConnect
            {...actionData}
            onClose={() => handleModalCloseResetData(onModalClose)}
            onSuccess={() => {
              idsOfEnquiredInventoryItems.current = [
                ...idsOfEnquiredInventoryItems.current,
                actionData.formId,
              ]
            }}
          />
        </FormModal>
      )}
      {!isAuthenticated && (
        <SignUpModal
          loginState={currentLoginState}
          isOpen={isSignUpOpen}
          onClose={onSignUpModalClose}
        />
      )}
      <SavedSuccessModal
        loginState={currentLoginState}
        isOpen={isSaveModalOpen}
        onClose={() => handleModalCloseResetData(onSaveModalClose)}
        itemType="courses"
        status={savedStatus}
      />
    </Fragment>
  )
}

export default CourseFinderResult
