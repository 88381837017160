import { useEffect, useState } from 'react'
import { SearchState } from 'react-instantsearch-core'
import {
  FinderFacetMenu,
  FinderFacetMenuItem,
  FinderWidget,
  InstantSearchWrapper,
  SearchAutocomplete,
} from 'ui'
import { searchPlugins } from 'ui/src/components/Search/SearchAutocomplete'
import { createConfig } from 'utils/src/helpers/algoliaSearch'
import { createLevelOfStudyFacets } from 'utils/src/helpers/createLevelOfStudyFacets'
import { ALGOLIA_INDICES, ROUTES } from '../../../constants'
import { useTranslation } from '../../../hooks/useTranslation'
import FinderSearchButton from '../FinderSearchButton'

const { searchClient, indexName } = createConfig({
  indexName: ALGOLIA_INDICES.COURSE_FINDER.MAIN,
})

const facetQuery = searchPlugins.createFacetQuery({
  searchClient,
  indexName,
  resultsPagePath: ROUTES.COURSE.RESULTS,
  facet: 'areasOfStudy.name',
})

const searchQuery = searchPlugins.createSearchQuery({
  searchClient,
  indexName,
  resultsPagePath: ROUTES.COURSE.RESULTS,
})

interface CourseFinderWidget {
  initialSearchState: SearchState
}

const CourseFinderWidget = ({ initialSearchState }: CourseFinderWidget) => {
  const [levelOfStudyFacets, setLevelOfStudyFacets] = useState<FinderFacetMenuItem[] | []>([])

  const { t } = useTranslation('search')

  async function getLevelOfStudyJSON() {
    const data = await import('taxonomy/src/data/level-of-study-broad.json')

    if (data) {
      setLevelOfStudyFacets(createLevelOfStudyFacets(data))
    }
  }

  useEffect(() => {
    getLevelOfStudyJSON()
  }, [])

  return (
    <InstantSearchWrapper
      searchState={initialSearchState}
      searchClient={searchClient}
      indexName={indexName}
    >
      <FinderWidget heading={t('course_finder_widget.heading') ?? 'Search for a course'}>
        <FinderWidget.Input minWidth="220px" width={{ base: 'full', md: '25%' }}>
          <FinderFacetMenu
            heading={t('course_finder_widget.facet_heading') ?? 'Levels of Study'}
            placeholder={t('course_finder_widget.facet_default_value') ?? 'All levels of study'}
            attribute="levelOfStudy.name"
            facets={levelOfStudyFacets}
          />
        </FinderWidget.Input>
        <FinderWidget.Input width={{ base: 'full', md: '75%' }}>
          <SearchAutocomplete
            title={t('course_finder_widget.heading')}
            placeholder={t('course_finder_widget.search_input_placeholder')}
            facet="areasOfStudy.name"
            resultsPath={ROUTES.COURSE.RESULTS}
            emptyQueryDropdownTitle={t('popular_searches')}
            dropdownTitle={t('course_finder_widget.suggested_courses')}
            plugins={[
              facetQuery,
              searchQuery,
              searchPlugins.popularCourseCategories(t('popular_searches')),
            ]}
            showLabel={false}
            shouldRedirectOnSubmit={true}
          />
        </FinderWidget.Input>
        <FinderWidget.SearchButton w={{ base: 'full', md: 'auto' }}>
          <FinderSearchButton resultsPagePath={ROUTES.COURSE.RESULTS} />
        </FinderWidget.SearchButton>
      </FinderWidget>
    </InstantSearchWrapper>
  )
}

export default CourseFinderWidget
