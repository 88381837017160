import { useAuth0 } from '@auth0/auth0-react'
import { StudyOptionItemType, StudyOptionStatus } from 'ui'
import { getStudyOptions, useUser } from '../context'
import { updateInventoryStatus } from '../helpers/sdClient'

function useRemoveInventoryItem() {
  const {
    userState: { profile },
    userDispatch,
  } = useUser()
  const { getAccessTokenSilently } = useAuth0()

  async function handleRemoveItem(itemId: string, itemType: StudyOptionItemType) {
    try {
      const accessToken = await getAccessTokenSilently()
      // Remove the item
      await updateInventoryStatus(profile, accessToken, {
        itemId,
        itemType,
        status: StudyOptionStatus.Archived,
      })
      await getStudyOptions(userDispatch, profile, accessToken)
    } catch (error) {
      console.log(error)
    }
  }

  return handleRemoveItem
}

export default useRemoveInventoryItem
