import { Box, Stack } from '@chakra-ui/react'
import { format } from 'date-fns'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  DashboardUpdatedItemProps,
  DetailItem,
  FinderResultItem,
  StudyOptionItemType,
  StudyOptionsAPILearnerInventoryItemModel,
  StudyOptionStatus,
} from 'ui'
import { ROUTES } from '../../../constants'
import { getAPIItem } from '../../../helpers'
import useRemoveInventoryItem from '../../../hooks/useRemoveInventoryItem'
import { validateScholarshipDetailProps } from '../../../templates/ScholarshipDetailPage/helpers'

export interface ScholarshipHit {
  title: string
  providerName: string
  eligibilityCriteria: string
  applicationInstructions: string
  id: string
  displayValue: string
  numberOfAwards: string
  providerLogoUrl: string
}

type ScholarshipHitsProps = {
  hits: ScholarshipHit[]
  apiScholarshipHitItems: StudyOptionsAPILearnerInventoryItemModel[]
  removedItems: DashboardUpdatedItemProps[]
  setRemovedItems: Dispatch<SetStateAction<DashboardUpdatedItemProps[]>>
}

function ScholarshipHits({
  hits,
  apiScholarshipHitItems,
  removedItems,
  setRemovedItems,
}: ScholarshipHitsProps) {
  const handleRemoveItem = useRemoveInventoryItem()

  const createDetailItems = (hit: ScholarshipHit): DetailItem[] | [] => {
    const { numberOfAwards, displayValue } = hit

    return [
      // ENZ-1260 - Hidden until it is ready to be re-added at a later date
      // ...(displayValue ? [{ label: 'Value', value: displayValue }] : []),
      ...(numberOfAwards ? [{ label: 'Number of awards', value: numberOfAwards }] : []),
    ]
  }

  const [removedIds, setRemovedIds] = useState([
    ...removedItems.map((removedItem) => {
      return removedItem.id
    }),
  ])
  useEffect(() => {
    setRemovedIds([
      ...removedItems.map((removedItem) => {
        return removedItem.id
      }),
    ])
  }, [removedItems])

  return (
    <span>
      {[...hits].map((hit: ScholarshipHit) => {
        const validScholarshipHitProps = validateScholarshipDetailProps(hit)
        const detailItems = createDetailItems(hit)
        const { status, updated } = getAPIItem(apiScholarshipHitItems, hit.id)
        const lastUpdated = updated && format(new Date(updated), 'dd MMM yyyy')

        return (
          <FinderResultItem key={hit.id} rowLayout hide={removedIds.includes(hit.id)}>
            <FinderResultItem.LeftContent itemStatus={status} />
            <Box position="relative" w="full">
              {validScholarshipHitProps.providerLogoUrl && (
                <FinderResultItem.Logo
                  provider={`${validScholarshipHitProps.providerName}`}
                  logoUrl={validScholarshipHitProps.providerLogoUrl}
                />
              )}
              <FinderResultItem.MainContent>
                <FinderResultItem.Title
                  title={validScholarshipHitProps.title}
                  subTitle={validScholarshipHitProps.providerName}
                  link={`${ROUTES.SCHOLARSHIP.DETAIL}/${validScholarshipHitProps.id}`}
                />
                <FinderResultItem.Description
                  intro={validScholarshipHitProps.eligibilityCriteriaHtmlString}
                />
                {detailItems && detailItems.length > 0 && (
                  <FinderResultItem.DetailItems detailItems={detailItems} />
                )}
              </FinderResultItem.MainContent>
              {status === StudyOptionStatus.Contacted && lastUpdated && (
                <FinderResultItem.LastContacted lastContacted={lastUpdated} />
              )}
              <FinderResultItem.Divider />
              <Stack order={{ md: 2 }} direction={{ base: 'column', lg: 'row' }} spacing="10px">
                <FinderResultItem.DetailsButton
                  link={`${ROUTES.SCHOLARSHIP.DETAIL}/${validScholarshipHitProps.id}`}
                  detailCta={{
                    label: 'See details',
                  }}
                />
                <FinderResultItem.RemoveButton
                  itemStatus={status}
                  handleClick={() => {
                    setRemovedItems([
                      {
                        id: hit.id,
                        name: validScholarshipHitProps.title,
                        prevStatus: status,
                        newStatus: StudyOptionStatus.Archived,
                      },
                      ...removedItems,
                    ])
                    setTimeout(() => {
                      handleRemoveItem(hit.id, StudyOptionItemType.Scholarship)
                    }, 250)
                  }}
                />
              </Stack>
            </Box>
          </FinderResultItem>
        )
      })}
    </span>
  )
}

export default ScholarshipHits
