import { Divider, Grid, GridItem } from '@chakra-ui/react'
import { connectRefinementList, SearchState } from 'react-instantsearch-core'
import { Configure } from 'react-instantsearch-dom'
import {
  finderBoxShadow,
  FinderFacetMenu,
  FinderWidget,
  InstantSearchWrapper,
  SearchAutocomplete,
} from 'ui'
import { searchPlugins } from 'ui/src/components/Search/SearchAutocomplete'
import { sortLevelOfSchools } from 'utils/src/helpers'
import { createConfig } from 'utils/src/helpers/algoliaSearch'
import useFacetValueFetch from 'utils/src/hooks/useFacetValueFetch'
import { ALGOLIA_INDICES, ROUTES } from '../../../constants'
import { useTranslation } from '../../../hooks/useTranslation'
import FinderSearchButton from '../FinderSearchButton'

interface SchoolFinderWidgetProps {
  initialSearchState?: SearchState
}

const { searchClient, indexName } = createConfig({
  indexName: ALGOLIA_INDICES.PROVIDER_FINDER.MAIN,
})

export const providerDetailPlugin = searchPlugins.createProviderDetailPlugin({
  searchClient,
  indexName,
  filters: 'type.name:"NZ School"',
})

const VirtualRefinementList = connectRefinementList(() => null)

const SchoolFinderWidget = ({ initialSearchState = {} }: SchoolFinderWidgetProps) => {
  const TYPE_NAME_ATTRIBUTE = 'type.name'
  const SUBTYPE_NAME_ATTRIBUTE = 'subtypeList.name'
  const SCHOOL_CONFIGURE_FILTER = 'type.name:"NZ School"'
  const REGIONS_CONFIG = 'regions.name'

  const subtypeList = useFacetValueFetch({
    attribute: SUBTYPE_NAME_ATTRIBUTE,
    indexName,
  })

  const subtypes = [...new Set([...sortLevelOfSchools(subtypeList)])]

  const { t } = useTranslation('search')

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={{ base: 0, xl: 6 }}>
      <GridItem colSpan={{ base: 12, xl: 7 }}>
        <InstantSearchWrapper
          searchState={initialSearchState}
          searchClient={searchClient}
          indexName={indexName}
          editStateOnChange={(state) => {
            {
              /* manipulates the current state by deleting subtypeList.name
              from the state if the placeholder is selected (no value) so that the initialSearchState
              (refinementList) is used, else returns the original state */
            }
            if (state?.menu && state.menu['subtypeList.name'] === '') {
              delete state.menu['subtypeList.name']
            }
          }}
        >
          <FinderWidget
            heading={t('school_finder_widget.heading') ?? 'Find a New Zealand School'}
            borderTopRadius={{ base: 0, lg: 20 }}
            borderBottomRadius={{ base: 0, xl: 20 }}
            boxShadow={{ base: 'none', xl: finderBoxShadow }}
          >
            <Configure filters={SCHOOL_CONFIGURE_FILTER} />
            <FinderWidget.Input width={{ base: 'full', lg: '50%' }}>
              <FinderFacetMenu
                heading=""
                facets={subtypes}
                attribute={SUBTYPE_NAME_ATTRIBUTE}
                placeholder={t('school_finder_widget.facet_default_value')}
              />
            </FinderWidget.Input>
            <VirtualRefinementList attribute={TYPE_NAME_ATTRIBUTE} />
            <FinderWidget.Input width={{ base: 'full', lg: '50%' }}>
              <FinderFacetMenu
                heading=""
                attribute={REGIONS_CONFIG}
                placeholder={t('school_finder_widget.facet_heading')}
              />
            </FinderWidget.Input>
            <VirtualRefinementList attribute={REGIONS_CONFIG} />
            <FinderWidget.SearchButton w={{ base: 'full', md: 'auto' }}>
              <FinderSearchButton resultsPagePath={ROUTES.PROVIDER.RESULTS} />
            </FinderWidget.SearchButton>
          </FinderWidget>
        </InstantSearchWrapper>
      </GridItem>
      <GridItem colSpan={12} display={{ xl: 'none' }} bg="white">
        <Divider borderColor="deepblue.100" p={0} m="0 auto" w="calc(100% - 56px)" />
      </GridItem>
      <GridItem colSpan={{ base: 12, xl: 5 }} mt={{ base: '-2px', xl: 0 }}>
        <InstantSearchWrapper
          searchState={initialSearchState}
          searchClient={searchClient}
          indexName={indexName}
        >
          <FinderWidget
            heading={
              t('school_finder_widget.search_input_placeholder') ?? 'Know the name of the School?'
            }
            borderTopRadius={{ base: 0, xl: 20 }}
            boxShadow={{
              base: '0px 20px 25px 0 rgba(0,0,0, 0.1)',
              xl: finderBoxShadow,
            }}
          >
            <VirtualRefinementList attribute={TYPE_NAME_ATTRIBUTE} />
            <FinderWidget.Input w="full">
              <SearchAutocomplete
                title=""
                placeholder={t('search_input_placeholder')}
                facet={SCHOOL_CONFIGURE_FILTER}
                resultsPath={ROUTES.PROVIDER.RESULTS}
                emptyQueryDropdownTitle={t('popular_searches')}
                dropdownTitle={t('school_finder_widget.suggested_schools')}
                plugins={[providerDetailPlugin]}
                showLabel={false}
                shouldRedirectOnSubmit={true}
              />
            </FinderWidget.Input>
          </FinderWidget>
        </InstantSearchWrapper>
      </GridItem>
    </Grid>
  )
}

export default SchoolFinderWidget
