import { useAuth0 } from '@auth0/auth0-react'
import { Accordion, Box, GridItem, useDisclosure } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import { Fragment, useRef, useState } from 'react'
import { connectRefinementList } from 'react-instantsearch-core'
import { TEALIUM_SEARCH_FILTER_MAPS } from 'tracking'
import {
  FilterAccordionItem,
  FilterButton,
  FinderFilterPills,
  FinderResults,
  GridLayout,
  InstantSearchWrapper,
  ResultFilters,
  ResultsFacetMenu,
  ResultsSearchBox,
  SortBy,
  StaticFacetRefinement,
  useHandleModalCloseResetData,
} from 'ui'
import { useEnquiryModalOnSignIn } from 'ui/src/forms/helpers/enquiryForm'
import { createConfig, createURL } from 'utils/src/helpers/algoliaSearch'
import useBrowseObjectsFetch, {
  BrowseObjectResult,
  getFacetAttributeValues,
} from 'utils/src/hooks/useBrowseObjectsFetch'
import SignUpModal, { LoginConnectProps, SignUpType } from '../../../components/SignUpModal'
import { EnquiryFormConnectProps } from '../../../connectors'
import { ALGOLIA_FILTER_MAPS, ALGOLIA_INDICES } from '../../../constants'
import { getProfile, useUser } from '../../../context'
import { sortByDescendingCount } from '../../../helpers'
import { ScholarshipHits } from '../SearchHit'

const SCHOLARSHIP_PROVIDER_SELECT_LIMIT = 500
const COUNTRY_FACET_LIMIT = 195 // There are 195 countries in the world
const NATIONALITIES_ATTRIBUTE = 'nationalities'
const SUBJECT_OF_STUDY_ATTRIBUTE = 'subjectsOfStudy.name'
const PROVIDER_SUBTYPE_ATTRIBUTE = 'providerSubtypeList.name'
const LEVELS_OF_STUDY_ATTRIBUTE = 'levelsOfStudy.name'

interface ScholarshipFinderBrowseFetch {
  providerSubtypeList: {
    name: string
  }[]
  levelsOfStudy: {
    name: string
  }[]
  subjectsOfStudy: {
    name: string
  }[]
  nationalities: string
  objectID: string
}

const { indexName, searchClient } = createConfig({
  indexName: ALGOLIA_INDICES.SCHOLARSHIP_FINDER.MAIN,
})

const scholarshipFinderVirtualList = [
  'subjectsOfStudy.name',
  'levelsOfStudy.name',
  'nationalities',
  'providerSubtypeList.name',
]

const VirtualRefinementList = connectRefinementList(() => null)
const VirtualRefinementLists = () => {
  return (
    <>
      {scholarshipFinderVirtualList.map((attribute) => {
        return <VirtualRefinementList key={attribute} attribute={attribute} />
      })}
    </>
  )
}

ScholarshipFinderResults.FilterProvider = function ScholarshipFinderFilterProvider() {
  return (
    <Box>
      <ResultsFacetMenu
        heading="All providers"
        attribute="providerName"
        limit={SCHOLARSHIP_PROVIDER_SELECT_LIMIT}
      />
    </Box>
  )
}

ScholarshipFinderResults.SortBy = function ScholarshipFinderResultSortBy() {
  return (
    <Box>
      <SortBy
        defaultRefinement={ALGOLIA_INDICES.SCHOLARSHIP_FINDER.MAIN}
        items={[
          {
            value: ALGOLIA_INDICES.SCHOLARSHIP_FINDER.MAIN,
            label: 'Relevancy',
          },
          {
            value: ALGOLIA_INDICES.SCHOLARSHIP_FINDER.ASCENDING,
            label: 'A - Z',
          },
          {
            value: ALGOLIA_INDICES.SCHOLARSHIP_FINDER.DESCENDING,
            label: 'Z - A',
          },
        ]}
      />
    </Box>
  )
}

ScholarshipFinderResults.Filters = function ScholarshipFinderResultsFilters({
  filterFacetValues,
}: {
  filterFacetValues: [] | BrowseObjectResult<ScholarshipFinderBrowseFetch>
}) {
  const nationalities = getFacetAttributeValues(filterFacetValues, NATIONALITIES_ATTRIBUTE)
  const subjectsOfStudy = getFacetAttributeValues(filterFacetValues, SUBJECT_OF_STUDY_ATTRIBUTE)
  const providerSubtype = getFacetAttributeValues(filterFacetValues, PROVIDER_SUBTYPE_ATTRIBUTE)
  const levelsOfStudy = getFacetAttributeValues(filterFacetValues, LEVELS_OF_STUDY_ATTRIBUTE)

  return (
    <Accordion defaultIndex={[0]} allowMultiple>
      {/* Nationalities */}
      <FilterAccordionItem attribute="nationalities" title="Location">
        <StaticFacetRefinement
          limit={COUNTRY_FACET_LIMIT}
          staticItems={nationalities}
          attribute={NATIONALITIES_ATTRIBUTE}
          sort={sortByDescendingCount}
        />
      </FilterAccordionItem>
      {/* Provider Type */}
      <FilterAccordionItem attribute={PROVIDER_SUBTYPE_ATTRIBUTE} title="Education Provider Type">
        <StaticFacetRefinement
          staticItems={providerSubtype}
          attribute={PROVIDER_SUBTYPE_ATTRIBUTE}
          sort={sortByDescendingCount}
        />
      </FilterAccordionItem>
      {/* Levels Of Study */}
      <FilterAccordionItem attribute={LEVELS_OF_STUDY_ATTRIBUTE} title="Level Of Study">
        <StaticFacetRefinement
          staticItems={levelsOfStudy}
          attribute={LEVELS_OF_STUDY_ATTRIBUTE}
          sort={sortByDescendingCount}
        />
      </FilterAccordionItem>
      {/* Subjects Of Study */}
      <FilterAccordionItem attribute={SUBJECT_OF_STUDY_ATTRIBUTE} title="Area Of Study">
        <StaticFacetRefinement
          staticItems={subjectsOfStudy}
          attribute={SUBJECT_OF_STUDY_ATTRIBUTE}
          sort={sortByDescendingCount}
        />
      </FilterAccordionItem>
    </Accordion>
  )
}

ScholarshipFinderResults.Results = function ScholarshipFinderResultsResults({
  nbHits,
  query,
  openEnquiryForm,
  idsOfEnquiredInventoryItems,
}: {
  query: string
  nbHits: number
  openEnquiryForm: (props: EnquiryFormConnectProps) => void
  idsOfEnquiredInventoryItems: string[]
}) {
  return (
    <FinderResults.Results>
      <FinderResults.ResultsBar>
        <ScholarshipFinderResults.FilterProvider />
        <ScholarshipFinderResults.SortBy />
      </FinderResults.ResultsBar>
      {nbHits ? (
        <FinderResults.ResultsContainer sideBarHeading="Scholarship providers">
          <ScholarshipHits
            idsOfEnquiredInventoryItems={idsOfEnquiredInventoryItems}
            openEnquiryForm={openEnquiryForm}
          />
        </FinderResults.ResultsContainer>
      ) : (
        <FinderResults.NoResults query={query} />
      )}
      <FinderResults.Footer />
    </FinderResults.Results>
  )
}

const FormModal = dynamic(() => import('ui/src/forms/FormModal'))
const EnquiryFormConnect = dynamic(() => import('../../../connectors/EnquiryForm'))

function ScholarshipFinderResults() {
  const idsOfEnquiredInventoryItems = useRef<string[]>([])

  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()

  const {
    isOpen: isSignUpOpen,
    onOpen: onSignUpModalOpen,
    onClose: onSignUpModalClose,
  } = useDisclosure()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { isAuthenticated, isLoading, user, getAccessTokenSilently } = useAuth0()
  const { userDispatch } = useUser()

  const handleModalCloseResetData = useHandleModalCloseResetData()

  const [currentLoginState, setCurrentLoginState] = useState<LoginConnectProps>({
    openModal: false,
  })

  const [actionData, setActionData] = useState<EnquiryFormConnectProps | null>(null)

  const filterFacetValues = useBrowseObjectsFetch<ScholarshipFinderBrowseFetch>({
    attributes: [
      NATIONALITIES_ATTRIBUTE,
      SUBJECT_OF_STUDY_ATTRIBUTE,
      PROVIDER_SUBTYPE_ATTRIBUTE,
      LEVELS_OF_STUDY_ATTRIBUTE,
    ],
    indexName,
  })

  useEnquiryModalOnSignIn(onModalOpen, setActionData)

  const handleEnquiryClick = async (props: EnquiryFormConnectProps) => {
    if (!isLoading) {
      setActionData(props)
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently()
        await getProfile(userDispatch, user, accessToken)
        onModalOpen()
      } else {
        onSignUpModalOpen()
        setCurrentLoginState({
          loginType: SignUpType.Enquiry,
          openModal: true,
          inventory: props,
        })
      }
    }
  }

  return (
    <Fragment>
      <InstantSearchWrapper
        indexName={indexName}
        searchClient={searchClient}
        searchState={{}}
        createURL={createURL}
        mapTealiumTrackingFields={TEALIUM_SEARCH_FILTER_MAPS.SCHOLARSHIP_FINDER_RESULTS}
        urlFilterMap={ALGOLIA_FILTER_MAPS.SCHOLARSHIP_FINDER_RESULTS}
      >
        <FinderResults>
          <FinderResults.Header title="Find a scholarship">
            <VirtualRefinementLists />
            <GridLayout>
              <GridItem colSpan={12}>
                <ResultsSearchBox
                  title="Search for a scholarship"
                  placeholder="Search by name or keyword"
                  showDivider={false}
                />
              </GridItem>
            </GridLayout>
            <ResultFilters
              searchClient={searchClient}
              indexName={indexName}
              isOpen={isOpen}
              onClose={onClose}
            >
              <ScholarshipFinderResults.Filters filterFacetValues={filterFacetValues} />
            </ResultFilters>
            <FinderResults.FilterContainer>
              {/** @ts-ignore: This needs to be remedied, difficulty in typing a "HOC'd" HOC. See connectCurrentRefinment function. **/}
              <FilterButton onOpen={onOpen} ignoreRefinements={['providerName']} />
              {/** @ts-ignore: This needs to be remedied, difficulty in typing a "HOC'd" HOC. See connectCurrentRefinment function. **/}
              <FinderFilterPills ignoreRefinements={['providerName']} />
            </FinderResults.FilterContainer>
          </FinderResults.Header>
          <FinderResults.Body>
            {({ searchResults }) => {
              const { nbHits, query } = searchResults || {}
              return (
                <ScholarshipFinderResults.Results
                  nbHits={nbHits}
                  query={query}
                  openEnquiryForm={handleEnquiryClick}
                  idsOfEnquiredInventoryItems={idsOfEnquiredInventoryItems.current}
                />
              )
            }}
          </FinderResults.Body>
        </FinderResults>
      </InstantSearchWrapper>
      {isModalOpen && actionData && (
        <FormModal isOpen={isModalOpen} onClose={() => handleModalCloseResetData(onModalClose)}>
          <EnquiryFormConnect
            {...actionData}
            onClose={() => handleModalCloseResetData(onModalClose)}
            onSuccess={() => {
              idsOfEnquiredInventoryItems.current = [
                ...idsOfEnquiredInventoryItems.current,
                actionData.formId,
              ]
            }}
          />
        </FormModal>
      )}
      <SignUpModal
        loginState={currentLoginState}
        isOpen={isSignUpOpen}
        onClose={onSignUpModalClose}
      />
    </Fragment>
  )
}

export default ScholarshipFinderResults
