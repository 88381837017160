import { Divider, Grid, GridItem } from '@chakra-ui/react'
import { MenuProvided } from 'react-instantsearch-core'
import {
  finderBoxShadow,
  FinderFacetMenu,
  FinderWidget,
  InstantSearchWrapper,
  SearchAutocomplete,
} from 'ui'
import { searchPlugins } from 'ui/src/components/Search/SearchAutocomplete'
import { createConfig } from 'utils/src/helpers/algoliaSearch'
import { sortAlphabetically } from 'utils/src/helpers/sortAlphabetically'
import { ALGOLIA_INDICES, ROUTES } from '../../../constants'
import { useTranslation } from '../../../hooks/useTranslation'
import FinderSearchButton from '../FinderSearchButton'

const { searchClient, indexName } = createConfig({
  indexName: ALGOLIA_INDICES.SCHOLARSHIP_FINDER.MAIN,
})

export const scholarshipDetailPlugin = searchPlugins.createScholarshipDetailPlugin({
  searchClient,
  indexName,
})

const sortNationalities = (nationalities: MenuProvided['items'], t) => {
  const alphabetisedNationalities = nationalities.sort(sortAlphabetically)

  const nationalitiesReferenceOrder = [t('scholarship_finder_widget.facet_default_value')]
  const orderedNationalities = nationalitiesReferenceOrder.flatMap((reference) =>
    alphabetisedNationalities.filter((nationality) => nationality.label === reference)
  )

  return [...new Set([...orderedNationalities, ...alphabetisedNationalities])]
}

const ScholarshipFinderWidget = () => {
  const TITLE_ATTRIBUTE = 'title'
  const NATIONALITIES_ATTRIBUTE = 'nationalities'
  const NATIONALITY_LIMIT = 500
  const initialSearchState = {}

  const { t } = useTranslation('search')

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={{ base: 0, xl: 6 }}>
      <GridItem colSpan={{ base: 12, xl: 7 }}>
        <InstantSearchWrapper
          searchState={initialSearchState}
          searchClient={searchClient}
          indexName={indexName}
        >
          <FinderWidget
            heading={t('scholarship_finder_widget.facet_heading') ?? 'Find a scholarship'}
            borderTopRadius={{ base: 0, lg: 20 }}
            borderBottomRadius={{ base: 0, xl: 20 }}
            boxShadow={{ base: 'none', xl: finderBoxShadow }}
          >
            <FinderWidget.Input width={'full'}>
              <FinderFacetMenu
                limit={NATIONALITY_LIMIT}
                heading=""
                attribute={NATIONALITIES_ATTRIBUTE}
                placeholder={t('scholarship_finder_widget.facet_heading')}
                transformItems={(items) => sortNationalities(items, t)}
              />
            </FinderWidget.Input>
            <FinderWidget.SearchButton w={{ base: 'full', md: 'auto' }}>
              <FinderSearchButton resultsPagePath={ROUTES.SCHOLARSHIP.RESULTS} />
            </FinderWidget.SearchButton>
          </FinderWidget>
        </InstantSearchWrapper>
      </GridItem>
      <GridItem colSpan={12} display={{ xl: 'none' }} bg="white">
        <Divider borderColor="deepblue.100" p={0} m="0 auto" w="calc(100% - 56px)" />
      </GridItem>
      <GridItem colSpan={{ base: 12, xl: 5 }} mt={{ base: '-2px', xl: 0 }}>
        <InstantSearchWrapper
          searchState={initialSearchState}
          searchClient={searchClient}
          indexName={indexName}
        >
          <FinderWidget
            heading={
              t('scholarship_finder_widget.search_input_placeholder') ??
              'Know the name of the scholarship?'
            }
            borderTopRadius={{ base: 0, xl: 20 }}
            boxShadow={{
              base: '0px 20px 25px 0 rgba(0,0,0, 0.1)',
              xl: finderBoxShadow,
            }}
          >
            <FinderWidget.Input w="full">
              <SearchAutocomplete
                title=""
                placeholder={t('search_input_placeholder')}
                facet={TITLE_ATTRIBUTE}
                resultsPath={ROUTES.SCHOLARSHIP.RESULTS}
                emptyQueryDropdownTitle={t('popular_searches')}
                dropdownTitle={t('scholarship_finder_widget.suggested_scholarships')}
                plugins={[scholarshipDetailPlugin]}
                showLabel={false}
                shouldRedirectOnSubmit={true}
              />
            </FinderWidget.Input>
          </FinderWidget>
        </InstantSearchWrapper>
      </GridItem>
    </Grid>
  )
}

export default ScholarshipFinderWidget
