import { Box } from '@chakra-ui/react'
import { SearchIcon } from 'icons'
import { connectStateResults, StateResultsProvided } from 'react-instantsearch-core'
import { Button } from 'ui'
import { createURL } from 'utils/src/helpers/algoliaSearch'
import { useTranslation } from '../../../hooks/useTranslation'

interface FinderSearchButtonProps extends StateResultsProvided {
  resultsPagePath: string
}

const FinderSearchButton = connectStateResults(
  ({ resultsPagePath, searchState = {} }: FinderSearchButtonProps) => {
    const searchStateToURLParams = createURL(searchState, false)

    const { t } = useTranslation('search')

    return (
      <Button
        href={{ pathname: resultsPagePath, query: searchStateToURLParams }}
        rightIcon={
          <Box boxSize={{ base: 7, lg: 5 }}>
            <SearchIcon />
          </Box>
        }
        width="full"
        aria-label="Search button"
        height={'60px'}
      >
        {t('search_button')}
      </Button>
    )
  }
)

export default FinderSearchButton
