import { connectRefinementList, SearchState } from 'react-instantsearch-core'
import { FinderFacetMenu, FinderWidget, InstantSearchWrapper, SearchAutocomplete } from 'ui'
import { searchPlugins } from 'ui/src/components/Search/SearchAutocomplete'
import { createConfig } from 'utils/src/helpers/algoliaSearch'
import { ALGOLIA_INDICES, ROUTES } from '../../../constants'
import { useTranslation } from '../../../hooks/useTranslation'
import FinderSearchButton from '../FinderSearchButton'

interface ProviderFinderWidgetProps {
  initialSearchState?: SearchState
}

const { searchClient, indexName } = createConfig({
  indexName: ALGOLIA_INDICES.PROVIDER_FINDER.MAIN,
})

const providerDetailPlugin = searchPlugins.createProviderDetailPlugin({
  searchClient,
  indexName,
  filters: '',
})

const VirtualRefinementList = connectRefinementList(() => null)

const ProviderFinderWidget = ({ initialSearchState = {} }: ProviderFinderWidgetProps) => {
  const TYPE_NAME_ATTRIBUTE = 'type.name'
  const SUBTYPE_LIST_NAME_ATTRIBUTE = 'subtypeList.name'

  const { t } = useTranslation('search')

  return (
    <InstantSearchWrapper
      searchState={initialSearchState}
      searchClient={searchClient}
      indexName={indexName}
    >
      <FinderWidget heading={t('provider_finder_widget.heading') ?? 'Find an education provider'}>
        <FinderWidget.Input minWidth="220px" width={{ base: 'full', md: '50%' }}>
          <FinderFacetMenu
            heading={t('provider_finder_widget.facet_heading') ?? 'Provider type'}
            attribute={SUBTYPE_LIST_NAME_ATTRIBUTE}
            placeholder={t('provider_finder_widget.facet_default_value') ?? 'Any provider type'}
          />
        </FinderWidget.Input>

        <VirtualRefinementList attribute={TYPE_NAME_ATTRIBUTE} />
        <FinderWidget.Input width={{ base: 'full' }}>
          <SearchAutocomplete
            title={t('provider_finder_widget.search_input_placeholder')}
            placeholder={t('search_input_placeholder')}
            facet={TYPE_NAME_ATTRIBUTE}
            resultsPath={ROUTES.PROVIDER.RESULTS}
            emptyQueryDropdownTitle={t('popular_searches')}
            dropdownTitle={t('provider_finder_widget.suggested_providers')}
            plugins={[providerDetailPlugin]}
            showLabel={false}
            shouldRedirectOnSubmit={true}
          />
        </FinderWidget.Input>
        <FinderWidget.SearchButton>
          <FinderSearchButton resultsPagePath={ROUTES.PROVIDER.RESULTS} />
        </FinderWidget.SearchButton>
      </FinderWidget>
    </InstantSearchWrapper>
  )
}

export default ProviderFinderWidget
