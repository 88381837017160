import { Box, Flex } from '@chakra-ui/react'
import { format } from 'date-fns'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { EnquiryFormConnectProps } from '../../../connectors'
import { createTagList, createUtagStartEnquiryEvent, INVENTORY_LABELS } from 'tracking'
import {
  DashboardUpdatedItemProps,
  DetailItem,
  FinderResultItem,
  FORM_COMPONENTS,
  StudyOptionItemType,
  StudyOptionsAPILearnerInventoryItemModel,
  StudyOptionStatus,
} from 'ui'
import { ROUTES } from '../../../constants'
import { getAPIItem } from '../../../helpers'
import useRemoveInventoryItem from '../../../hooks/useRemoveInventoryItem'
import useUpdateInventoryItem from '../../../hooks/useUpdateInventoryItem'
import {
  FinderResultItemEnquiryButton,
  FinderResultItemSaveButton,
} from '../FinderResultItemButtons'

interface TaxonomyField {
  name: string
  tag: string
}

export interface ProviderHit {
  cities: string[]
  countries: TaxonomyField[]
  defaultCampus: string
  id: string
  logoUrl: string
  name: string
  objectID: string
  description: string
  regions: TaxonomyField[]
  regionsWithCountriesAndCities: string[]
  subtype: TaxonomyField
  type: TaxonomyField
  url: string
  subtypeList: TaxonomyField[]
}

interface InstitutionHitsProps {
  hits: ProviderHit[]
  openEnquiryForm: ({ courseName, providerName, providerSubtype }: EnquiryFormConnectProps) => void
  apiProviderHitItems: StudyOptionsAPILearnerInventoryItemModel[]
  removedItems: DashboardUpdatedItemProps[]
  setRemovedItems: Dispatch<SetStateAction<DashboardUpdatedItemProps[]>>
  setEnquiredDataToUpdate?: Dispatch<SetStateAction<DashboardUpdatedItemProps | null>>
}

function InstitutionHits({
  hits,
  apiProviderHitItems,
  openEnquiryForm,
  removedItems,
  setRemovedItems,
  setEnquiredDataToUpdate,
}: InstitutionHitsProps) {
  const handleRemoveItem = useRemoveInventoryItem()
  const handleSaveItem = useUpdateInventoryItem()

  const createDetailItems = (hit: ProviderHit): DetailItem[] | [] => {
    const { regions = [] } = hit
    const regionList = regions.map((region) => region.name).join(', ')
    return [...(regions.length ? [{ label: 'Regions', value: regionList }] : [])]
  }

  const [removedIds, setRemovedIds] = useState([
    ...removedItems.map((removedItem) => {
      return removedItem.id
    }),
  ])

  useEffect(() => {
    setRemovedIds([
      ...removedItems.map((removedItem) => {
        return removedItem.id
      }),
    ])
  }, [removedItems])

  return (
    <span>
      {[...hits].map((hit: ProviderHit) => {
        const detailItems = createDetailItems(hit)
        const { matchScore, status, updated } = getAPIItem(apiProviderHitItems, hit.id)
        const lastUpdated = updated && format(new Date(updated), 'dd MMM yyyy')

        return (
          <FinderResultItem key={hit.id} rowLayout hide={removedIds.includes(hit.id)}>
            <FinderResultItem.LeftContent matchScore={matchScore} itemStatus={status} />
            <Box position="relative" w="full">
              {hit.logoUrl && <FinderResultItem.Logo provider={hit.name} logoUrl={hit.logoUrl} />}
              <FinderResultItem.MainContent>
                <FinderResultItem.Title
                  title={hit.name}
                  subTitle={hit.subtypeList
                    .map((subtype) => {
                      return subtype.name
                    })
                    .join(', ')}
                  link={`${ROUTES.PROVIDER.DETAIL}/${hit.id}`}
                />
                <FinderResultItem.Description intro={hit.description} />
                {detailItems && detailItems.length > 0 && (
                  <FinderResultItem.DetailItems detailItems={detailItems} />
                )}
              </FinderResultItem.MainContent>
              {status === StudyOptionStatus.Contacted && lastUpdated && (
                <FinderResultItem.LastContacted lastContacted={lastUpdated} />
              )}
              <FinderResultItem.Divider />

              <Flex
                wrap="wrap"
                order={{ md: 2 }}
                direction={{ base: 'column', lg: 'row' }}
                sx={{ gap: '10px' }}
              >
                {status !== StudyOptionStatus.Contacted &&
                hit?.type?.tag !== 'nz-scholarship-only-provider' ? (
                  <FinderResultItemEnquiryButton
                    variant="solid"
                    inventoryId={hit.id}
                    openEnquiryForm={() => {
                      createUtagStartEnquiryEvent({
                        pageName: INVENTORY_LABELS.PROVIDER.PAGE_NAME,
                        inventoryId: hit?.id ?? '',
                        inventoryObjectType: INVENTORY_LABELS.PROVIDER.INVENTORY_OBJECT_TYPE,
                        levelOfStudy: '',
                        areaOfStudyArray: [],
                        subjectOfStudyArray: [],
                        deliveryModeArray: [],
                        courseName: '',
                        providerName: hit?.name ?? '',
                        providerType: hit?.type?.tag ?? '',
                        providerSubtype: createTagList(hit?.subtypeList),
                        campusRegionArray: createTagList(hit?.regions),
                        linkLabel: 'Enquire',
                      })
                      openEnquiryForm({
                        providerName: hit.name,
                        providerSubtype: createTagList(hit?.subtypeList),
                        formId: hit.id,
                        formComponent:
                          hit.type.name === 'NZ School'
                            ? FORM_COMPONENTS.SCHOOL_ENQUIRY
                            : FORM_COMPONENTS.PROVIDER_ENQUIRY,
                        onClose: () => false,
                      })
                      setEnquiredDataToUpdate &&
                        setEnquiredDataToUpdate({
                          id: hit.id,
                          name: hit.name,
                          prevStatus: status,
                          newStatus: StudyOptionStatus.Contacted,
                        })
                    }}
                  />
                ) : (
                  <></>
                )}
                <FinderResultItem.DetailsButton
                  link={`${ROUTES.PROVIDER.DETAIL}/${hit.id}`}
                  detailCta={{
                    label: 'See provider details',
                  }}
                />
                {status !== StudyOptionStatus.Contacted && status !== StudyOptionStatus.Saved ? (
                  <FinderResultItemSaveButton
                    inventoryId={hit.id}
                    saveInventoryItem={() => {
                      setRemovedItems([
                        {
                          id: hit.id,
                          name: hit.name,
                          prevStatus: status,
                          newStatus: StudyOptionStatus.Saved,
                        },
                        ...removedItems,
                      ])
                      setTimeout(() => {
                        handleSaveItem(hit.id, StudyOptionItemType.Provider)
                      }, 250)
                    }}
                  />
                ) : (
                  <></>
                )}
                <FinderResultItem.RemoveButton
                  itemStatus={status}
                  handleClick={() => {
                    setRemovedItems([
                      {
                        id: hit.id,
                        name: hit.name,
                        prevStatus: status,
                        newStatus: StudyOptionStatus.Archived,
                      },
                      ...removedItems,
                    ])
                    setTimeout(() => {
                      handleRemoveItem(hit.id, StudyOptionItemType.Provider)
                    }, 250)
                  }}
                />
              </Flex>
            </Box>
          </FinderResultItem>
        )
      })}
    </span>
  )
}

export default InstitutionHits
